import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircle, faAngleDown, faQuestion, faPen, faMoneyBill, faBullhorn, faExclamation, faMouse, faLongArrowAltRight, faLevelDownAlt, faList, faAngleRight, faMinus, faAdd, faEnvelope, faBars, faTimes, faSignOutAlt, faAddressCard, faCog, faBorderAll, faPlusCircle, faQuoteLeft, faQuoteRight, faPlayCircle, faHeart, faSearch, faTag, faStar, faChevronLeft, faChevronRight, faChevronDown, faChartBar, faClock, faMapMarkerAlt, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { faSquareCheck } from '@fortawesome/free-regular-svg-icons'
import { faFacebookF, faLinkedinIn, faTwitter, faInstagram, faYoutube, faPinterestP } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
    faCircle,
    faAngleDown,
    faQuestion,
    faYoutube,
    faPen,
    faMoneyBill,
    faBullhorn,
    faExclamation,
    faMouse,
    faLongArrowAltRight,
    faLevelDownAlt,
    faList,
    faAngleRight,
    faMinus,
    faAdd,
    faSquareCheck,
    faEnvelope,
	faBars,
	faTimes,
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faFacebookF,
	faLinkedinIn,
	faTwitter,
	faInstagram,
	faPinterestP,
	faHeart,
	faChartBar,
	faClock,
	faMapMarkerAlt,
	faCalendar,
	faTag,
	faStar,
	faSearch,
	faQuoteLeft,
	faQuoteRight,
	faPlayCircle,
	faAddressCard,
	faCog,
	faBorderAll,
	faPlusCircle,
	faSignOutAlt
)

Vue.component('fa-icon', FontAwesomeIcon)
