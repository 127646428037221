
/** Import Vue **/

import Vue from "vue";
window.Vue = require('vue').default;

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

Vue.component('vue-slider', VueSlider)

/** FontAwesome icons **/
import './icons.js';
import moment from 'moment';

Vue.config.devtools = true;

Vue.filter('formatDate', function(value)
{
    if (value)
    {
        return moment(value).format('MMM Do, Y');
    }
});

/** Navigation **/
Vue.component('navigation', () => import('./components/navigation/Navigation.vue'))
Vue.component('navigation-dropdown', () => import('./components/navigation/Dropdown.vue'))
Vue.component('navigation-item', () => import('./components/navigation/Item.vue'))
Vue.component('navigation-search', () => import('./components/navigation/Search.vue'))

/** Loan Calculator **/
Vue.component('loan-calculator', () => import('./components/LoanCalculator.vue'))

// Privacy/cookie Policy
Vue.component('cookiebot-button', () => import('./components/CookieBotModalButton.vue'));

/** Tab Section **/
Vue.component('tab-section', () => import('./components/TabSection.vue'))
Vue.component('horizontal-tab-section', () => import('./components/HorizontalTabSection.vue'))

/** Accordion Section **/
Vue.component('accordion', () => import('./components/Accordion.vue'))
Vue.component('navigation-accordion', () => import('./components/NavigationAccordion.vue'))
Vue.component('tabbed-accordion', () => import('./components/TabbedAccordion.vue'))
Vue.component('faqs', () => import('./components/Faqs.vue'))
Vue.component('faqs-search', () => import('./components/FaqsSearch.vue'))

/** collection Block **/
Vue.component('business-advice-block', () => import('./components/BusinessAdviceBlock.vue'))
Vue.component('success_stories-block', () => import('./components/SuccessStoriesBlock.vue'))
Vue.component('transmit-news-block', () => import('./components/TransmitNewsBlock.vue'))
Vue.component('collection-search', () => import('./components/CollectionSearch.vue'))
Vue.component('global-search-block',() => import('./components/GlobalSearchBlock.vue'))

/** Swipers **/
Vue.component('success-stories-swiper',() => import('./components/SuccessStoriesSwiper.vue'))

/** New vue App init **/
const app = new Vue({
    el: '#app'
});

// sticky side navigation bar
var side_nav = document.getElementById("side_nav");

// check if the user is using a mobile phone
if(side_nav && !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
{
    var stop            = side_nav.offsetTop - 100,
        right           = side_nav.offsetLeft,
        contentHeight   = document.getElementById('scrollContent').scrollHeight,
        side_nav_height = document.getElementById('side_nav').scrollHeight,
        docBody         = document.documentElement || document.body.parentNode || document.body,
        hasOffset       = window.pageYOffset !== undefined,
        scrollTop;

    window.onscroll = function (e)
    {

        scrollTop = hasOffset ? window.pageYOffset : docBody.scrollTop;

        if(scrollTop >= contentHeight - 300)
        {
            side_nav.className = '';
            side_nav.style.paddingTop = (contentHeight - side_nav_height - 35)+'px';
        }
        else if (scrollTop >= stop)
        {
            // stick the div
            side_nav.className = 'stick';
            side_nav.style.left = right+'px';
            side_nav.style.paddingTop = '0px';
        }
        else
        {
            // release the div
            side_nav.className = '';
        }
    }
}

function posY(elm)
{
    var test = elm, top = 0;

    while(!!test && test.tagName.toLowerCase() !== "body") {
        top += test.offsetTop;
        test = test.offsetParent;
    }

    return top;
}

function viewPortHeight()
{
    var de = document.documentElement;

    if(!!window.innerWidth)
    { return window.innerHeight; }
    else if( de && !isNaN(de.clientHeight) )
    { return de.clientHeight; }

    return 0;
}

function scrollY()
{
    if( window.pageYOffset ) { return window.pageYOffset; }
    return Math.max(document.documentElement.scrollTop, document.body.scrollTop);
}

function checkvisible( elm ) {
    var vpH = viewPortHeight(), // Viewport Height
        st = scrollY(), // Scroll Top
        y = posY(elm);
    return (y < (vpH + st));
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function animateValue(obj, start, end, duration, commas)
{
    let startTimestamp = null;
    const step = (timestamp) =>
    {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);

        if (commas) {
            obj.innerHTML = numberWithCommas(Math.floor(progress * (end - start) + start));
        } else {
            obj.innerHTML = Math.floor(progress * (end - start) + start);
        }

        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    };

    window.requestAnimationFrame(step);
}

const entrepreneurs_backed = document.getElementById('entrepreneurs_backed');
const entrepreneurs_backed_end = entrepreneurs_backed?.getAttribute('data-value') || null;
let entrepreneurs_backed_ran = false;

const money_lent = document.getElementById('money_lent');
const money_lent_end = money_lent?.getAttribute('data-value')?.replaceAll(',', '') || null;
let money_lent_ran = false;

document.addEventListener('scroll', (e) => {
    if (entrepreneurs_backed && checkvisible(entrepreneurs_backed) && !entrepreneurs_backed_ran) {
        entrepreneurs_backed_ran = true;
        animateValue(entrepreneurs_backed, 0, entrepreneurs_backed_end, 3000,false);
    }
    if (money_lent && checkvisible(entrepreneurs_backed) && !money_lent_ran) {
        money_lent_ran = true;
        animateValue(money_lent, 0, money_lent_end, 3000,true);
    }
});
